body {
  --text: #222222;
  --textSecondary: #66686d;
  --border: #eaeaea;
  --back: #ffffff;
  --backTransparent: rgba(255, 255, 255, 0);
  --backSemiTransparent: rgba(255, 255, 255, 0.8);
  --primary: #6c19cf;
  --primaryTransparent: rgba(106, 25, 204, 0.2);
  --primaryLighter: #813fd1;
  --green: #2ccc89;
  --red: #f54849;
  color: var(--text);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--back);
}

body.dark {
  --back: #131313;
  --backTransparent: rgba(19, 19, 19, 0);
  --backSemiTransparent: rgba(15,15,15,0.8);
  --text: #fbfbfb;
  --textSecondary: #a8a8a8;
  --border: #4b4b4b;
  --primary: #8d39f5;
  --primaryTransparent:  rgb(127, 38, 235, 0.2);
  --primaryLighter: #a55cff;
}

body.scrollDisabled {
  overflow: hidden;
}

.container {
  max-width: 900px;
  padding: 0 24px;
  margin: auto;
}

a {
  color: var(--primary) !important;
  text-decoration: none !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--border);
  margin: 8px 0;
}

.react-toast-notifications__toast{
  max-width: calc(100vw - 16px)!important;
  margin-left: auto!important;;
  margin-right: auto!important;;
}

@media (max-width: 768px) {
  body.scrollDisabledMobile {
    overflow: hidden;
  }
}

.windows ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.windows ::-webkit-scrollbar-track {
  background: var(--back);
  margin: 4px;
}
 
.windows ::-webkit-scrollbar-thumb {
  background: var(--border); 
  border-radius: 4px;
}

.windows ::-webkit-scrollbar-thumb:hover {
  background: #cccccc; 
}

.react-datepicker-popper{
  z-index: 100!important;
}

.react-datepicker-wrapper, .react-datepicker__input-container{
  width: 100%;
}

.react-datepicker{
  border-radius: 7px!important;
  display: flex!important;
}

.react-datepicker__month-container, .react-datepicker__time-container {
  float: unset!important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
  margin: 0!important;
  height: unset!important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item::before{
  content: ""!important;
}

.react-datepicker__header{
  background-color: var(--accents_1)!important;
  border-color: var(--border)!important;
}

.react-datepicker, .react-datepicker__time-container{
  border-color: var(--border)!important;
}

.react-datepicker__current-month, .react-datepicker-time__header{
  color: var(--accents_4)!important;
  font-weight: inherit!important;
  font-size: .875rem!important;
}

.react-datepicker, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: inherit!important;
}

.react-datepicker__time-container .react-datepicker__time, .react-datepicker{
  background-color: var(--back)!important;
}

.react-datepicker__navigation--next{
  border-left-color: var(--border)!important;
}

.react-datepicker__navigation--previous{
  border-right-color: var(--border)!important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
  background-color: var(--border)!important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: var(--primaryLighter)!important;
  color: #fff!important
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
  background-color: var(--primary)!important;
  color: #fff!important
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover{
  background-color: var(--primary)!important;
  color: #fff!important
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover{
  background-color: var(--primary)!important;
  color: #fff!important
}