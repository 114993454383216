*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

body {
  --text: #222222;
  --textSecondary: #66686d;
  --border: #eaeaea;
  --back: #ffffff;
  --backTransparent: rgba(255, 255, 255, 0);
  --backSemiTransparent: rgba(255, 255, 255, 0.8);
  --primary: #6c19cf;
  --primaryTransparent: rgba(106, 25, 204, 0.2);
  --primaryLighter: #813fd1;
  --green: #2ccc89;
  --red: #f54849;
  color: var(--text);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: var(--back);
}

body.dark {
  --back: #131313;
  --backTransparent: rgba(19, 19, 19, 0);
  --backSemiTransparent: rgba(15,15,15,0.8);
  --text: #fbfbfb;
  --textSecondary: #a8a8a8;
  --border: #4b4b4b;
  --primary: #8d39f5;
  --primaryTransparent:  rgb(127, 38, 235, 0.2);
  --primaryLighter: #a55cff;
}

body.scrollDisabled {
  overflow: hidden;
}

.container {
  max-width: 900px;
  padding: 0 24px;
  margin: auto;
}

a {
  color: var(--primary) !important;
  text-decoration: none !important;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--border);
  margin: 8px 0;
}

.react-toast-notifications__toast{
  max-width: calc(100vw - 16px)!important;
  margin-left: auto!important;;
  margin-right: auto!important;;
}

@media (max-width: 768px) {
  body.scrollDisabledMobile {
    overflow: hidden;
  }
}

.windows ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.windows ::-webkit-scrollbar-track {
  background: var(--back);
  margin: 4px;
}
 
.windows ::-webkit-scrollbar-thumb {
  background: var(--border); 
  border-radius: 4px;
}

.windows ::-webkit-scrollbar-thumb:hover {
  background: #cccccc; 
}

.react-datepicker-popper{
  z-index: 100!important;
}

.react-datepicker-wrapper, .react-datepicker__input-container{
  width: 100%;
}

.react-datepicker{
  border-radius: 7px!important;
  display: flex!important;
}

.react-datepicker__month-container, .react-datepicker__time-container {
  float: unset!important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item{
  margin: 0!important;
  height: unset!important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item::before{
  content: ""!important;
}

.react-datepicker__header{
  background-color: var(--accents_1)!important;
  border-color: var(--border)!important;
}

.react-datepicker, .react-datepicker__time-container{
  border-color: var(--border)!important;
}

.react-datepicker__current-month, .react-datepicker-time__header{
  color: var(--accents_4)!important;
  font-weight: inherit!important;
  font-size: .875rem!important;
}

.react-datepicker, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: inherit!important;
}

.react-datepicker__time-container .react-datepicker__time, .react-datepicker{
  background-color: var(--back)!important;
}

.react-datepicker__navigation--next{
  border-left-color: var(--border)!important;
}

.react-datepicker__navigation--previous{
  border-right-color: var(--border)!important;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover{
  background-color: var(--border)!important;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
  background-color: var(--primaryLighter)!important;
  color: #fff!important
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
  background-color: var(--primary)!important;
  color: #fff!important
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover{
  background-color: var(--primary)!important;
  color: #fff!important
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover{
  background-color: var(--primary)!important;
  color: #fff!important
}
.Dashboard_Main__3Aq3e {
    width: calc(100% - 320px);
    padding: 48px 0;
    margin-left: 320px;
}

.Dashboard_label__1FMXw{
    padding: 0 12px;
    font-size: 12px;
    margin: 12px 0 8px 0;
    text-transform: uppercase;
    color: var(--textSecondary);
    font-weight: bold;
}

@media (max-width: 900px){
    .Dashboard_Main__3Aq3e{
        width: 100%;
        margin: 0;
        padding: 96px 0;
    }
}
.Drawer_Drawer__1197a{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    justify-content: space-between;
    padding: 24px;
    box-shadow: 1px 0 0 0 var(--border);
    position: fixed;
    overflow: auto;
    background-color: var(--back);
    -webkit-overflow-scrolling: touch;
    z-index: 24;
}

.Drawer_brand__Lpf05{
    margin-left: 12px;
    display: flex;
    align-items: center;
}

.Drawer_brand__Lpf05 span{
    width: calc(100% - 44px);
    margin-left: 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3px;
}

.Drawer_Drawer__1197a a {
    text-decoration: none!important;
}

.Drawer_menu__2etcq{
    padding: 24px 0;
}

.Drawer_copyright__2maLr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: var(--textSecondary);
}

.Drawer_copyright__2maLr a {
    color: inherit
}

.Drawer_backdrop__wS2JX{
    display: none;
    opacity: 0;
    pointer-events: none;
    z-index: 23;
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.2);
    transition: opacity .2s ease;
}

.dark .Drawer_backdrop__wS2JX{
    background-color: rgba(0,0,0,.5);
}

.Drawer_backdrop__wS2JX.Drawer_open__1-wRm{
    opacity: 1;
    pointer-events: all;  
}

.Drawer_burger__2TR2F{
    display: none;
    background-color: var(--primary);
    padding: 16px 12px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 25;
    left: 24px;
    transition: left .2s ease, transform .2s ease;
    box-shadow: 0 8px 16px -4px rgba(0,0,0,.3);
}

.Drawer_burger__2TR2F:active{
    transform: scale(.9);
}

.Drawer_burger__2TR2F.Drawer_open__1-wRm{
    left: 250px;
}

.Drawer_burger__2TR2F>div{
    width: 22px;
    height: 2px;
    background-color: #fff;
    transition: transform .2s ease, opacity .2s ease;
}

.Drawer_burger__2TR2F>div:not(:last-child){
    margin-bottom: 4px;
}

.Drawer_burger__2TR2F.Drawer_open__1-wRm>div{
    opacity: 0;
}

.Drawer_burger__2TR2F.Drawer_open__1-wRm>div:first-child{
    opacity: 1;
    transform: translateY(6px) rotate(45deg);
}

.Drawer_burger__2TR2F.Drawer_open__1-wRm>div:last-child{
    opacity: 1;
    transform: translateY(-6px) rotate(-45deg);
}

@media (max-width: 900px){
    .Drawer_brand__Lpf05{
        padding: 6px 0;
    }
    .Drawer_backdrop__wS2JX{
        display: block;
    }
    .Drawer_Drawer__1197a{
        transform: translateX(-100%);
        transition: transform .2s ease;
    }
    .Drawer_Drawer__1197a.Drawer_open__1-wRm{
        transform: none;
    }
    .Drawer_burger__2TR2F{
        position: fixed;
        top: 24px;
        left: 24px;
        display: block;
    }
}
.MenuItem_item__1kDFd{
    display: flex;
    align-items: center;
    --color: var(--primary);
    --colorTransparent: var(--primaryTransparent);
    color: var(--textSecondary);
    padding: 0 8px;
    font-size: 14px;
    border-radius: 4px;
    margin-bottom: 4px;
    background-color: transparent;
    transition: transform .2s ease, color .2s ease;
}

.MenuItem_item__1kDFd.MenuItem_noIcon__3bPmu{
    padding: 8px 12px;
    font-size: 13px;
}

.MenuItem_item__1kDFd.MenuItem_noIcon__3bPmu::before{
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    border-radius: 2px;
    background-color: var(--border);
    margin-right: 12px;
    transition: background-color .2s ease;
}


.MenuItem_item__1kDFd:hover, .MenuItem_active__bKfe1 .MenuItem_item__1kDFd{
    color: var(--text)
}

.MenuItem_item__1kDFd .MenuItem_icon__uHVyV{
    margin-right: 12px;
    padding: 8px;
    border-radius: 7px;
    transition: background-color .2s ease;
}

.MenuItem_item__1kDFd:hover .MenuItem_icon__uHVyV{
    background-color: var(--colorTransparent);
}

.MenuItem_active__bKfe1 .MenuItem_item__1kDFd .MenuItem_icon__uHVyV{
    background-color: var(--color);
    color: #fff;
}

.MenuItem_item__1kDFd svg{
    display: block;
}

.MenuItem_item__1kDFd:active{
    transform: scale(.98);
}

.MenuItem_item__1kDFd span{
    line-height: 1.2;
}

.MenuItem_item__1kDFd.MenuItem_noIcon__3bPmu span{
    width: calc(100% - 20px);
}

.MenuItem_active__bKfe1 .MenuItem_item__1kDFd.MenuItem_noIcon__3bPmu{
    background-color: var(--color);
    color: #fff;
}

.MenuItem_active__bKfe1 .MenuItem_item__1kDFd.MenuItem_noIcon__3bPmu::before{
    background-color: #fff;
}
.Loader_Loader__3_Wwf{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
}

.Loader_Loader__3_Wwf .Loader_element__3yE1w{
    display: block;
    height: 32px;
    width: 32px;
    border: 2px var(--border) solid;
    border-top-color: var(--primary);
    border-radius: 50%;
    -webkit-animation: Loader_rotate__2N4GI .5s linear infinite;
            animation: Loader_rotate__2N4GI .5s linear infinite;
}

@-webkit-keyframes Loader_rotate__2N4GI{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}

@keyframes Loader_rotate__2N4GI{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}
.Main_infoBox__2pU6n{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Main_infoBox__2pU6n:not(:last-of-type){
    padding-right: 20px;
    border-right: 1px solid var(--border);
    margin-right: 20px;
}

.Main_infoBoxLabel__hqjf2{
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--textSecondary);
    text-transform: uppercase;
}

.Main_infoBoxValue__31-Z3{
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
}

.Main_boxesWrap__25jOv{
    display: flex;
    overflow-x: auto;
    padding: 0 10px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.Main_userInfo__3NBAM{
    width: calc(100% + 10px);
    position: relative;
    margin: 24px 0 0 -10px;
}

.Main_userInfo__3NBAM:after, .Main_userInfo__3NBAM:before {
    position: absolute;
    top: 0;
    content: "";
    display: block;
    width: 10px;
    height: 100%;
    z-index: 1;
}

.Main_userInfo__3NBAM:before{
    background-image: linear-gradient(90deg, var(--back), var(--backTransparent));
    left: 0
}

.Main_userInfo__3NBAM:after{
    background-image: linear-gradient(270deg, var(--back), var(--backTransparent));
    right: 0;
}

.Main_cards__1DnDz{
    margin-top: 16px;
    display: grid;
    grid-gap: 24px;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
}

.Main_label__pxpRY{
    margin-top: 24px;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--textSecondary)
}

@media (max-width: 520px){
    .Main_cards__1DnDz{
        grid-template-columns: 1fr;
    }
}
.User_user__3DpY-{
    display: flex;
    align-items: center;
}

.User_user__3DpY- .User_avatar__2vfZY{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: var(--border);
    width: 40px;
    height: 40px;
    margin-right: 12px;
    border-radius: 50%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 0 1px rgba(0,0,0,.14) inset;
    color: var(--textSecondary)
}

.User_user__3DpY-:not(.User_big__3kpQK) .User_userEmail__33PXW{
    display: none;
}

.User_user__3DpY-.User_big__3kpQK .User_avatar__2vfZY{
    height: 64px;
    width: 64px;
    margin-right: 16px;
    font-size: 24px;
}

.User_user__3DpY-.User_small__S2vR4 .User_avatar__2vfZY{
    height: 32px;
    width: 32px;
    margin-right: 8px;
    font-size: 14px;
}

.User_userInfo___9WuN{
    width: calc(100% - 52px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.User_userInfo___9WuN>*{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.User_big__3kpQK .User_userInfo___9WuN{
    width: calc(100% - 80px);
}

.User_small__S2vR4 .User_userInfo___9WuN{
    width: calc(100% - 40px);
}

.User_userName__2hFIW{
    font-size: 15px;
}

.User_user__3DpY-:not(.User_big__3kpQK) .User_userName__2hFIW{
    color: var(--textSecondary)
}

.User_big__3kpQK .User_userName__2hFIW{
    font-size: 20px;
    font-weight: bold;
}

.User_small__S2vR4 .User_userName__2hFIW{
    font-size: 14px;
}

.User_userEmail__33PXW{
    font-size: 14px;
    color: var(--textSecondary);
}

.User_skeleton__1dvup .User_userName__2hFIW{
    background-color: var(--border);
    width: 128px;
    height: 18px;
    border-radius: 3px;
}

.User_skeleton__1dvup .User_avatar__2vfZY{
    box-shadow: none;
}
.Card_Card__1f8qq{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px var(--border) solid;
    border-radius: 7px;
    padding: 12px;
    color: inherit!important;
    cursor: pointer;
    transition: box-shadow .2s ease;
}

.Card_Card__1f8qq:hover{
    box-shadow: 0 4px 12px -4px rgba(0,0,0,.14);
}

.Card_courseTitle__1zAjz{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
}

.Card_courseDescription__22nkO{
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 8px;
    color: var(--textSecondary);
}
.Button_Button__3w4Cn {
  display: block;
  width: 100%;
  border: none;
  color: var(--primary);
  background-color: transparent;
  border: 2px var(--primary) solid;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  transition: border-color 0.2s ease, background-color 0.2s ease,
    box-shadow 0.2s ease, transform 0.2s ease;
}

.Button_Button__3w4Cn.Button_withIcon__3mmzL {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Button_Button__3w4Cn.Button_withIcon__3mmzL span {
  margin-left: 8px;
}

.Button_Button__3w4Cn.Button_small__2ag6M {
  padding: 4px 12px;
  font-size: 14px;
}

.Button_Button__3w4Cn:hover {
  background-color: var(--primaryTransparent);
}

.Button_Button__3w4Cn:focus {
  box-shadow: 0 4px 8px 0 var(--primaryTransparent);
  outline: none !important;
}

.Button_Button__3w4Cn:active {
  transform: scale(0.98);
}

.Button_Button__3w4Cn.Button_primary__2xUaq {
  background-color: var(--primary);
  color: #ffffff;
}

.Button_Button__3w4Cn.Button_primary__2xUaq:hover {
  background-color: var(--primaryLighter);
  border-color: var(--primaryLighter);
}

.Button_Button__3w4Cn.Button_disabled__1TgSc {
  cursor: not-allowed;
  background-color: var(--border) !important;
  color: var(--textSecondary) !important;
  border-color: var(--border) !important;
}

@media (max-width: 460px) {
  .Button_Button__3w4Cn.Button_withIcon__3mmzL span {
    width: calc(100% - 32px);
  }
}

.Chat_Chat__2_h-s{
    position: fixed;
    bottom: 24px;
    right: 24px;
}

.Chat_fab__A9xKY{
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    transition: transform .2s ease;
    margin-left: auto;
    margin-top: 16px;
}

.Chat_close__3oNcW{
    position: absolute;
    opacity: 0;
    transform: scale(.5);
}

.Chat_close__3oNcW, .Chat_icon__1hYPz{
    transition: transform .2s ease, opacity .2s ease;
}

.Chat_icon__1hYPz{
    opacity: 1;
    transform: none;
}

.Chat_fab__A9xKY.Chat_open__1erPJ .Chat_close__3oNcW{
    opacity: 1;
    transform: none;
}

.Chat_fab__A9xKY.Chat_open__1erPJ .Chat_icon__1hYPz{
    opacity: 0;
    transform: scale(.5);
}

.Chat_fab__A9xKY:active{
    transform: scale(0.95);
}

.Chat_header__2ixOD{
    padding: 8px 16px;
    background-color: var(--primary);
    color: #ffffff;
}

.Chat_window__1wAN5{
    position: fixed;
    background-color: var(--back);
    border-radius: 13px;
    overflow: hidden;
    max-width: 320px;
    width: 100%;
    box-shadow: 0 0 0 1px var(--border);
    pointer-events: none;
    right: 24px;
    bottom: 96px;
    opacity: 0;
    transform: translateY(-100px);
    transition: transform .2s ease, opacity .2s ease;
}

.Chat_window__1wAN5.Chat_open__1erPJ{
    pointer-events: all;
    opacity: 1;
    transform: none;
}

.Chat_messages__2RN2M{
    height: 360px;
    overflow-y: auto;
    padding: 8px 12px;
    -webkit-overflow-scrolling: touch;
}

.Chat_message__1j-d4{
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
}

.Chat_message__1j-d4.Chat_me__3GZcs{
    flex-direction: row-reverse;
}

.Chat_message__1j-d4.Chat_me__3GZcs .Chat_messageText__7T3ju{
    margin-left: auto;
}

.Chat_avatar__IGHHH{
    height: 32px;
    width: 32px;
    margin-right: 12px;
    display: flex;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--border);
    color: var(--textSecondary);
    background-size: cover;
}

.Chat_message__1j-d4.Chat_me__3GZcs .Chat_avatar__IGHHH{
    margin-right: 0;
    margin-left: 12px;
}

.Chat_message__1j-d4.Chat_me__3GZcs .Chat_messageDate__34MH7{
    text-align: right;
}

.Chat_messageBody__2IUpP{
    width: calc(100% - 44px);
}

.Chat_messageText__7T3ju{
    background-color: var(--border);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 7px;
    padding: 8px;
    font-size: 15px;
    line-height: 1.2;
    overflow-wrap: break-word;
    max-width: 100%;
}

.Chat_messageName__2zfOm{
    font-size: 13px;
    font-weight: 600;
    color: var(--primary);
    margin-bottom: 4px;
}

.dark .Chat_messageName__2zfOm{
    color: var(--primaryLighter);
}

.Chat_messageDate__34MH7{
    color: var(--textSecondary);
    font-size: 12px;
    margin-top: 4px;
}

.Chat_toolbar__23BSU{
    display: flex;
    align-items: stretch;
    border-top: 1px var(--border) solid;
}

.Chat_toolbar__23BSU input{
    display: block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    background-color: transparent;
    color: var(--text);
    font-size: 16px;
    width: calc(100% - 32px);
    padding: 10px 18px;
    outline: none!important;
}

.Chat_toolbar__23BSU button{
    display: block;
    outline: none!important;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    padding: 0;
    width: 64px;
    color: var(--textSecondary);
    background-color: transparent;
    transition: transform .2s ease;
}

.Chat_toolbar__23BSU button:active{
    transform: scale(0.9);
}

@media (max-width: 768px){
    .Chat_messages__2RN2M{
        height: calc(100vh - 260px);
    }
    .Chat_window__1wAN5{
        max-width: calc(100vw - 48px);
        margin-left: auto;
    }
}
.Input_Input__1_xcO {
  display: flex;
  flex-direction: column;
}
.Input_Input__1_xcO > * {
  display: block;
}

.Input_Input__1_xcO label {
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 6px;
  text-transform: uppercase;
  color: var(--textSecondary);
}

.Input_Input__1_xcO input, .Input_Input__1_xcO textarea {
  font-size: 16px;
  padding: 8px 14px;
  border: 1px var(--border) solid;
  background-color: var(--back);
  color: var(--text);
  border-radius: 5px;
}

.Input_Input__1_xcO textarea{
  min-height: 42px;
}

.Input_Input__1_xcO input:focus, .Input_Input__1_xcO textarea:focus {
  border-color: var(--primary);
  outline: none;
}

.Modal_modal__2g0Wb {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease;
}

.Modal_modal__2g0Wb.Modal_active__-U-UA {
    opacity: 1;
    pointer-events: all;
}

.Modal_modalBackdrop__2kTDb {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
}

.Modal_modalHeader__zCOij {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    align-items: center;
    border-bottom: 1px var(--border) solid;
}

.Modal_modalTitle__3Ztxc {
    width: calc(100% - 24px);
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
}

.Modal_modalWrap__1S7f1 {
    position: relative;
    z-index: 1;
    max-width: 480px;
    border-radius: 13px;
    background-color: var(--back);
    width: 100%;
    margin: 24px;
    transform: scale(.9);
    transition: transform .2s ease;
}

.Modal_modalContent__3Yi4s {
    padding: 24px;
    max-height: calc(100vh - 240px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

body.dark .Modal_modalWrap__1S7f1{
    box-shadow: 0 0 0 1px var(--border);
}

.Modal_modal__2g0Wb.Modal_active__-U-UA .Modal_modalWrap__1S7f1 {
    transform: none;
}

.Modal_close__afpsf {
    cursor: pointer;
    transition: opacity .3s ease;
}

.Modal_close__afpsf:hover {
    opacity: .7;
}
.Results_header__7L_12{
    margin: -24px -24px 8px;
    border-bottom: 1px var(--border) solid;
}
.Results_tabs__1tbEv{
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Results_tab__1RNo_{
    padding: 12px 20px;
    font-weight: 600;
    cursor: pointer;
    color: var(--textSecondary);
    font-size: 14px;
    border-bottom: 2px transparent solid;
}
.Results_tab__1RNo_.Results_active__1ypZm{
    border-color: var(--primary);
    color: var(--primary);
}

.Results_field__20pcw{
    padding: 12px 0;
}

.Results_fieldLabel__2rXYm{
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--textSecondary);
}

.Results_fieldValue__yodBd{
    font-size: 14px;
    color: var(--textSecondary);
}

.Results_fieldValue__yodBd b{
    font-size: 24px;
    font-weight: 600;
    color: var(--text);
}

.Results_Variant__1VHrs{
    display: flex;
    align-items: center;
    padding: 12px 0;
}

.Results_Variant__1VHrs:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Results_Variant__1VHrs span{
    width: calc(100% - 32px);
    font-size: 14px;
    color: var(--textSecondary);
    transition: color .2s ease;
}

.Results_dot__2MgXO{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    border: 1px var(--border) solid;
}

.Results_dot__2MgXO::after{
    content: "";
    display: block;
    height: 12px;
    width: 12px;
    background-color: var(--primary);
    border-radius: 50%;
    transform: scale(.5);
    opacity: 0;
    transition: transform .2s ease, opacity .2s ease;
}

.Results_selected__FM_25.Results_dot__2MgXO::after{
    transform: none;
    opacity: 1;
}

.Results_selected__FM_25 + span{
    color: var(--text)!important;
}

.Results_Toolbar__eYXNt{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Results_Toolbar__eYXNt button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Results_Toolbar__eYXNt .Results_arrowButton__3Fqtr{
    padding: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--primary);
    color: #ffffff;
    width: 32px;
    height: 32px;
    outline: none;
    border-radius: 50%;
    transition: transform .2s ease;
    cursor: pointer;
}

.Results_Toolbar__eYXNt .Results_arrowButton__3Fqtr:disabled{
    background-color: var(--border);
    color: var(--textSecondary)
}

.Results_Toolbar__eYXNt .Results_arrowButton__3Fqtr:active{
    transform: scale(.95);
}

.Results_arrowButton__3Fqtr svg{
    display: block;
}

.Results_variants__38qaI{
    margin-top: 8px;
}

.Results_answerHeader__3kXyz{
    border-bottom: 1px var(--border) solid;
    margin: 0 -24px 16px;
    position: relative;
}

.Results_answerHeader__3kXyz>div{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.Results_answerHeader__3kXyz::after, .Results_answerHeader__3kXyz::before{
    position: absolute;
    top: 0;
    content: "";
    display: block;
    pointer-events: none;
    width: 24px;
    height: 100%;
    z-index: 1;
}

.Results_answerHeader__3kXyz::after{
    right: 0;
    background: linear-gradient(270deg, var(--back), var(--backTransparent));
}

.Results_answerHeader__3kXyz::before{
    left: 0;
    background: linear-gradient(90deg, var(--back), var(--backTransparent));
}

.Results_stepWrap__133p3{
    display: flex;
    padding: 8px 24px 12px 24px;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Results_divider__2RxLo{
    height: 20px;
    width: 1px;
    margin: 0 12px;
    background-color: var(--border);
}

.Results_answerHeaderItem__1bhOZ{
    height: 24px;
    width: 24px;
    font-size: 13px;
    font-weight: 600;
    color: var(--textSecondary);
    background-color: var(--border);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.15;
    cursor: pointer;
}

.Results_answerHeaderItem__1bhOZ.Results_selected__FM_25{
    background-color: var(--primary);
    color: #ffffff;
}

.Results_contentDescription__2-Pkn{
    font-size: 18px;
    font-weight: 600;
}

.Results_answerRight__2RvJ6 b{
    text-transform: uppercase;
}
/*.results table {
  white-space: nowrap;
  font-size: 14px;
}

.results table th {
  text-transform: uppercase;
  color: var(--textSecondary);
}

.results table th,
.results table td {
  padding: 6px 8px;
  font-weight: bold;
}

.results .right {
  color: var(--green);
}

.results .wrong {
  color: var(--red);
}

.results tr {
  border-bottom: 1px solid var(--border);
}

.cell {
  border-left: 1px solid var(--border);
  text-align: center;
  text-transform: uppercase;
}*/

/* NEW */

.TeacherResults_table__2N0J8 {
  font-size: 14px;
  font-weight: bold;
  width: 1024px;
  max-width: 100%;
  max-height: 400px;
}

.TeacherResults_table__2N0J8 .TeacherResults_th__2AcM4,
.TeacherResults_table__2N0J8 .TeacherResults_td__3ocYe {
  padding: 5px;
  border-bottom: 1px solid var(--border);
  border-left: 1px solid var(--border);
  background-color: var(--back);
  overflow: hidden;
}

.TeacherResults_table__2N0J8 .TeacherResults_th__2AcM4 {
  color: var(--textSecondary);
  font-size: 12px;
  text-transform: uppercase;
}

.TeacherResults_table__2N0J8 .TeacherResults_td__3ocYe:first-child,
.TeacherResults_table__2N0J8 .TeacherResults_th__2AcM4:first-child {
  padding-left: 24px;
  border-right: 1px solid var(--border);
}

.TeacherResults_table__2N0J8 .TeacherResults_td__3ocYe:first-child + .TeacherResults_td__3ocYe,
.TeacherResults_table__2N0J8 .TeacherResults_th__2AcM4:first-child + .TeacherResults_th__2AcM4 {
  border-left: unset;
}

.TeacherResults_table__2N0J8 .TeacherResults_tr__2V_TW .TeacherResults_td__3ocYe:last-child,
.TeacherResults_table__2N0J8 .TeacherResults_th__2AcM4:last-child {
  border-right: unset;
}

.TeacherResults_table__2N0J8 .TeacherResults_tr__2V_TW:last-child .TeacherResults_td__3ocYe {
  border-bottom: unset;
}

.TeacherResults_table__2N0J8 .TeacherResults_tr__2V_TW .TeacherResults_td__3ocYe:last-child {
  color: var(--textSecondary);
}

.TeacherResults_table__2N0J8.TeacherResults_sticky__13aLg {
  overflow: scroll;
}
.TeacherResults_table__2N0J8.TeacherResults_sticky__13aLg .TeacherResults_header__3oLBm,
.TeacherResults_table__2N0J8.TeacherResults_sticky__13aLg .TeacherResults_footer__3abcP {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.TeacherResults_table__2N0J8.TeacherResults_sticky__13aLg .TeacherResults_header__3oLBm {
  top: 0;
}
.TeacherResults_table__2N0J8.TeacherResults_sticky__13aLg .TeacherResults_body__1rwer {
  position: relative;
  z-index: 0;
}
.TeacherResults_table__2N0J8.TeacherResults_sticky__13aLg [data-sticky-td] {
  position: -webkit-sticky;
  position: sticky;
}

.TeacherResults_cell__1IXoq {
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  width: 100%;
}

.Course_courseHeader__1SJxs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Course_courseHeader__1SJxs, .Course_card__3AbRf, .Course_sHeader__24Yb5{
    padding: 20px;
    border-radius: 13px;
    border: 1px var(--border) solid;
}

.Course_courseHeader__1SJxs h1{
    font-size: 24px;
    line-height: 1.2;
}

.Course_courseHeader__1SJxs p{
    font-size: 15px;
    color: var(--textSecondary);
}

.Course_label__3mWxz{
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: var(--textSecondary)
}

.Course_courseHeader__1SJxs .Course_label__3mWxz, .Course_sHeader__24Yb5 .Course_label__3mWxz{
    margin: 16px 0 8px 0;
}

.Course_card__3AbRf{
    margin-top: 24px;
}

.Course_card__3AbRf .Course_label__3mWxz{
    padding-bottom: 8px;
}

.Course_label__3mWxz.Course_borderBottom__6rlxq{
    border-bottom: 1px var(--border) solid;
}

.Course_collapse__cX9UZ{
    transition: height .4s ease;
}

.Course_weekHeader__3ktwv{
    padding: 10px 0;
    cursor: pointer;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px var(--border) solid;
}

.Course_weekHeader__3ktwv span{
    width: calc(100% - 32px);
    overflow: hidden;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.Course_weekHeader__3ktwv svg{
    display: block;
    transform: none;
    transition: transform .2s ease;
}

.Course_week__2CtXi.Course_open__1Nzli .Course_weekHeader__3ktwv svg{
    transform: rotate(180deg);
}

.Course_weekDescription__2HeEr{
    color: var(--textSecondary);
    margin: 12px 0 0 0;
}

.Course_weekDescription__2HeEr:empty{
    margin: 0;
}

.Course_lesson__UXYdX{
    padding: 16px 0;
    border-bottom: 1px var(--border) solid;
}

.Course_week__2CtXi:last-of-type .Course_lesson__UXYdX:last-of-type{
    border: none;
}

.Course_lessonName__3RISg{
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 8px;
}

.Course_lesson__UXYdX p{
    font-size: 15px;
    margin-bottom: 8px;
    color: var(--textSecondary);
}

.Course_lessonNumber__-DiAH{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
    color: var(--textSecondary)
}

.Course_grid__dFhDQ{
    display: grid;
    grid-gap: 24px;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
}

.Course_grid__dFhDQ .Course_card__3AbRf{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Course_quiz__hatwS{
    padding: 16px 0;
    
}

.Course_quiz__hatwS:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Course_quizInfo__3wQGS{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
}

.Course_quizInfoLabel__WJ2Qg, .Course_quizInfoValue__2l1xZ{
    font-size: 14px;
    color: var(--textSecondary)
}

.Course_quizTitle__1qlkk{
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 8px;
}

.Course_quizInfoLabel__WJ2Qg{
    font-weight: 600;
}

.Course_quizButtons__2YTEm{
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Course_quizButtons__2YTEm>button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Course_topItem__WMOxu{
    padding: 8px 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Course_topItem__WMOxu:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Course_topItemValue__1ogFG{
    font-weight: 600;
}

.Course_student__1r0jT{
    padding: 8px 0;
}

.Course_student__1r0jT:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Course_quizzesWrap__3RCE_{
    height: 455px;
    margin: 0 -20px -20px -20px;
    padding: 0 20px 20px 20px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.Course_quizzesCard__3ydSz{
    position: relative;
    overflow: hidden;
}

.Course_quizzesCard__3ydSz::after, .Course_eventsCard__2FXjf::after{
    display: block;
    position: absolute;
    pointer-events: none;
    content: "";
    bottom: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 24px;
    background-image: linear-gradient(0deg, var(--back), var(--backTransparent));
}

.Course_sHeaderTitle__WMF-h{
    height: 28px;
    max-width: 220px;
    width: 100%;
    background-color: var(--border);
    border-radius: 3px;
    margin-bottom: 8px;
}

.Course_sHeaderDescription__3LBhD{
    background-color: var(--border);
    border-radius: 3px;
    height: 22px;
    max-width: 256px;
    width: 100%;
    margin-bottom: 16px;
}

.Course_asTeacher__1xMcr ::-webkit-scrollbar{
    height: 12px;
    width: 12px;
}

.Course_asTeacher__1xMcr ::-webkit-scrollbar-track {
    margin: 0;
    background-color: var(--border);
}

.Course_asTeacher__1xMcr ::-webkit-scrollbar-thumb{
    border-radius: 6px;
    background-color: var(--primaryTransparent);
}

.Course_asTeacher__1xMcr ::-webkit-scrollbar-thumb:hover{
    background-color: var(--primary);
}

.Course_eventsWrap__3qIFV{
    height: 455px;
    margin: 0 -20px -20px -20px;
    padding: 0 20px 20px 20px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.Course_events__3RxR3{
    width: 100%;
    overflow-x: auto;
}

.Course_eventTitle__jE95s{
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 8px;
}

.Course_eventBody__2FnPl{
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--textSecondary);
}

.Course_eventDate__34SnL{
    color: var(--textSecondary);
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.Course_topButton__2pBkz{
    position: absolute;
    color: var(--textSecondary);
    padding: 0;
    top: 20px;
    right: 20px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.Course_topButton__2pBkz svg{
    display: block;
}

.Course_spacer__RJ3XO{
    height: 20px;
}

@media (max-width: 948px){
    .Course_grid__dFhDQ{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 900px){
    .Course_grid__dFhDQ{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px){
    .Course_weekHeader__3ktwv span{
        font-size: 16px;
    }
    .Course_weekDescription__2HeEr{
        font-size: 14px;
    }
    .Course_lessonNumber__-DiAH{
        font-size: 14px;
    }
    .Course_grid__dFhDQ{
        grid-template-columns: 1fr;
    }
}
.Tasks_taskHeader__3WKdx {
  border-bottom: 1px var(--border) solid;
  margin: -16px -24px 16px;
  position: relative;
}

.Tasks_taskHeader__3WKdx > div {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.Tasks_taskHeader__3WKdx::after,
.Tasks_taskHeader__3WKdx::before {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  width: 24px;
  height: 100%;
  z-index: 1;
}

.Tasks_taskHeader__3WKdx::after {
  right: 0;
  background: linear-gradient(270deg, var(--back), var(--backTransparent));
}

.Tasks_taskHeader__3WKdx::before {
  left: 0;
  background: linear-gradient(90deg, var(--back), var(--backTransparent));
}

.Tasks_stepWrap__3LZUr {
  display: flex;
  padding: 8px 24px 12px 24px;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Tasks_divider__1TJxa {
  height: 20px;
  width: 1px;
  margin: 0 12px;
  background-color: var(--border);
}

.Tasks_taskHeaderItem__2YiPT {
  height: 24px;
  width: 24px;
  font-size: 13px;
  font-weight: 600;
  color: var(--textSecondary);
  background-color: var(--border);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.15;
  cursor: pointer;
}

.Tasks_taskHeaderItem__2YiPT.Tasks_selected__FoVfc {
  background-color: var(--primary);
  color: #ffffff;
}

.Tasks_content__3npwl {
  padding: 12px 0;
}

.Tasks_content__3npwl p:empty {
  display: none;
}

.Tasks_content__3npwl img {
  max-width: 100%;
}

.Tasks_Tasks__2xisn textarea {
  display: block;
  width: 100%;
  resize: vertical;
  min-height: 56px;
  max-height: 160px;
  font-size: 16px;
  padding: 8px 14px;
  border: 1px var(--border) solid;
  background-color: var(--back);
  color: var(--text);
  border-radius: 5px;
  outline: none !important;
}

.Tasks_Tasks__2xisn textarea:focus {
  border-color: var(--primary);
  outline: none;
}

.Tasks_Toolbar__1z4rR {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  background-image: linear-gradient(
    to top,
    var(--back),
    var(--backTransparent)
  );
  bottom: -24px;
  padding-bottom: 24px;
}

.Tasks_Toolbar__1z4rR button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Tasks_Toolbar__1z4rR .Tasks_arrowButton__2CZzv {
  padding: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--primary);
  color: #ffffff;
  width: 32px;
  height: 32px;
  outline: none;
  border-radius: 50%;
  transition: transform 0.2s ease;
  cursor: pointer;
}

.Tasks_Toolbar__1z4rR .Tasks_arrowButton__2CZzv:disabled {
  background-color: var(--border);
  color: var(--textSecondary);
}

.Tasks_Toolbar__1z4rR .Tasks_arrowButton__2CZzv:active {
  transform: scale(0.95);
}

.Tasks_arrowButton__2CZzv svg {
  display: block;
}

.Tasks_answers__1_1-_ {
  border-top: 1px var(--border) solid;
  padding-top: 24px;
}

.Tasks_label__3TMGB {
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--textSecondary);
}

.Tasks_answerRow__3JC1y {
  padding: 8px 0 12px 0;
  border-bottom: 1px var(--border) solid;
}

.Tasks_answerRowStudent__1RoYF {
  font-size: 14px;
  font-weight: 600;
  color: var(--textSecondary);
}

.Tasks_answerRowContent__2LHoK:not(:empty)::before {
  content: "Ответ: ";
  font-weight: 600;
  color: var(--textSecondary);
}

.Tasks_answerRowContent__2LHoK:empty::after {
  content: "Нет ответа";
  font-weight: 600;
  text-decoration: line-through;
  color: var(--textSecondary);
  opacity: 0.8;
}

.Tasks_answerRowControls__seVYV {
  position: relative;
  overflow: hidden;
  padding: 12px;
  margin-top: 8px;
  border-radius: 12px;
  border: 1px var(--border) solid;
}

.Tasks_answerRowControls__seVYV.Tasks_checked__yyP04::after {
  content: "Проверенно";
  display: block;
  font-size: 14px;
  background-color: var(--primary);
  color: #ffffff;
  padding: 0 36px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(34px, 12px) rotate(24deg);
}

.Tasks_answerRowCheckbox__1bD9s {
  margin-bottom: 8px;
}

.Tasks_answerBlock__ViA9a {
  padding: 12px;
  margin-top: 8px;
  border-radius: 12px;
  border: 1px var(--border) solid;
}

.Tasks_answerStatus__2mCPv.Tasks_ok__aPDPM {
  color: var(--green);
}

.Tasks_answerStatus__2mCPv.Tasks_bad__29f2f {
  color: var(--red);
}

.Tasks_answerStatus__2mCPv::before {
  content: "Статус: ";
  font-weight: 600;
  color: var(--textSecondary) !important;
}

.Tasks_answerComment__uXtuE::before {
  content: "Комментарий: ";
  font-weight: 600;
  color: var(--textSecondary) !important;
}

.Tasks_answerText__3wMTN::before {
  content: "Ответ: ";
  font-weight: 600;
  color: var(--textSecondary) !important;
}

.Checkbox_Checkbox__2M36o{
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

.Checkbox_Checkbox__2M36o .Checkbox_box__RL3Z5{
    display: block;
    height: 24px;
    width: 24px;
    border: 1px var(--border) solid;
    border-radius: 5px;
    margin-right: 8px;
    transition: all .3s ease;
}

.Checkbox_Checkbox__2M36o.Checkbox_selected__3I2FS .Checkbox_box__RL3Z5{
    background-color: var(--primary);
    border-color: var(--primary);   
}

.Checkbox_Checkbox__2M36o .Checkbox_box__RL3Z5::after{
    display: block;
    height: 22px;
    width: 22px;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
    transform: rotate(45deg);
    opacity: 0;
    transition: transform .2s ease;
}

.Checkbox_Checkbox__2M36o.Checkbox_Checkbox__2M36o.Checkbox_selected__3I2FS .Checkbox_box__RL3Z5::after{
    transform: none;
    opacity: 1;
}

.Checkbox_Checkbox__2M36o span{
    font-size: 14px;
    line-height: 1.2;
}
.Lesson_lessonHeader__1kbni, .Lesson_card__1KHqQ{
    padding: 20px;
    border-radius: 13px;
    border: 1px var(--border) solid;
}

.Lesson_lessonHeader__1kbni h1{
    font-size: 24px;
    line-height: 1.2;
    margin: 0;
}

.Lesson_lessonHeader__1kbni p{
    font-size: 15px;
    margin: 8px 0 0 0;
    color: var(--textSecondary);
}

.Lesson_card__1KHqQ{
    margin-top: 24px;
}

.Lesson_content__1a8vd img{
    max-width: 100%;
}

.Lesson_resourses__3kWJh video, .Lesson_resourses__3kWJh audio{
    max-width: 100%;
    width: 100%;
}

.Lesson_resourses__3kWJh video{
    border-radius: 7px;
    outline: none;
}

.Lesson_buttons__30b0V{
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.Lesson_buttons__30b0V:empty{
    margin: 0;
}

.Lesson_buttons__30b0V>button:first-child{
    margin-right: 8px;
}

.Lesson_buttons__30b0V>button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.Quiz_ready__15r6m{
    min-height: calc(100vh - 96px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Quiz_ready__15r6m button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

.Quiz_ready__15r6m p{
    max-width: 360px;
    font-size: 14px;
    line-height: 1.2;
    color: var(--textSecondary)
}

.Quiz_card__KZFdk{
    padding: 20px;
    border-radius: 13px;
    border: 1px var(--border) solid
}

@media (max-width: 900px){
    .Quiz_ready__15r6m{
        min-height: calc(100vh - 192px);
    }
}

.Quiz_timer__2_6cb {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 12px;
}

.Quiz_Variant__281og{
    display: flex;
    align-items: center;
    padding: 12px 0;
    cursor: pointer;
}

.Quiz_Variant__281og:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Quiz_Variant__281og span{
    width: calc(100% - 32px);
    font-size: 14px;
    color: var(--textSecondary);
    transition: color .2s ease;
}

.Quiz_dot__T1L_y{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 8px;
    border: 1px var(--border) solid;
}

.Quiz_dot__T1L_y::after{
    content: "";
    display: block;
    height: 12px;
    width: 12px;
    background-color: var(--primary);
    border-radius: 50%;
    transform: scale(.5);
    opacity: 0;
    transition: transform .2s ease, opacity .2s ease;
}

.Quiz_selected__2edvw.Quiz_dot__T1L_y::after{
    transform: none;
    opacity: 1;
}

.Quiz_selected__2edvw + span{
    color: var(--text)!important;
}

.Quiz_Toolbar__2ULwy{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Quiz_Toolbar__2ULwy button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Quiz_Toolbar__2ULwy .Quiz_arrowButton__1mp4v{
    padding: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--primary);
    color: #ffffff;
    width: 32px;
    height: 32px;
    outline: none;
    border-radius: 50%;
    transition: transform .2s ease;
    cursor: pointer;
}

.Quiz_Toolbar__2ULwy .Quiz_arrowButton__1mp4v:disabled{
    background-color: var(--border);
    color: var(--textSecondary)
}

.Quiz_Toolbar__2ULwy .Quiz_arrowButton__1mp4v:active{
    transform: scale(.95);
}

.Quiz_arrowButton__1mp4v svg{
    display: block;
}

.Quiz_variants__y9BJv{
    margin-top: 8px;
}

.Quiz_contentTitle__QI1uh{
    font-size: 15px;
    color: var(--textSecondary);
}

.Quiz_Question__WAgc1 img{
    max-width: 100%;
    border-radius: 7px;
}

.Quiz_Question__WAgc1 audio{
    width: 100%;
    outline: none;
}

.Quiz_contentDescription__VNcBx{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
}

.Quiz_fab__27QXt{
    position: fixed;
    right: 24px;
    top: 24px;
    padding: 0 16px;
    background-color: var(--primary);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    outline: none!important;
    transition: transform .2s ease;
}

.Quiz_fab__27QXt:active{
    transform: scale(.96);
}

.Quiz_fab__27QXt svg{
    display: block;
    margin-right: 8px;
}
.Settings_Settings__3QwO8{
    max-width: 480px;
}

.Settings_field__32bZL{
    margin-bottom: 16px;
}

.Settings_card__7QzlQ{
    padding: 20px;
    border-radius: 13px;
    border: 1px var(--border) solid;
    margin-top: 24px;
}

.Settings_cardTitle__3anAn{
    font-size: 20px;
    margin-bottom: 16px;
    line-height: 1.2;
}
.Tournaments_tournaments__1jbXZ{
    display: grid;
    grid-gap: 24px;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
    padding-top: 24px;
}

.Tournaments_tournaments__1jbXZ .Tournaments_tournament__1rsTm{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.Tournaments_tabs__3QVw9{
    display: flex;
    margin: 0 -24px;
    border-bottom: 1px var(--border) solid;
}

.Tournaments_tab__27lKM{
    padding: 12px 24px;
    font-weight: 600;
    cursor: pointer;
    color: var(--textSecondary);
    font-size: 14px;
    border-bottom: 2px transparent solid;
    margin-bottom: -1px;
    transition: border-color .2s ease, color .2s ease;
}

.Tournaments_tab__27lKM.Tournaments_active__TK1ug{
    border-color: var(--primary);
    color: var(--primary);
}

.Tournaments_infoBox__3y_9S{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Tournaments_infoBox__3y_9S:not(:last-of-type){
    padding-right: 20px;
    border-right: 1px solid var(--border);
    margin-right: 20px;
}

.Tournaments_infoBoxLabel__DduEl{
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    color: var(--textSecondary);
    text-transform: uppercase;
}

.Tournaments_infoBoxValue__kHJ-V{
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    white-space: nowrap;
}

.Tournaments_boxesWrap__2W2md{
    display: flex;
    overflow-x: auto;
    padding: 0 10px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.Tournaments_userInfo__9O0la{
    width: calc(100% + 10px);
    position: relative;
    margin: 24px 0 12px -10px;
}

.Tournaments_userInfo__9O0la:after, .Tournaments_userInfo__9O0la:before {
    position: absolute;
    top: 0;
    content: "";
    display: block;
    width: 10px;
    height: 100%;
    z-index: 1;
}

.Tournaments_userInfo__9O0la:before{
    background-image: linear-gradient(90deg, var(--back), var(--backTransparent));
    left: 0
}

.Tournaments_userInfo__9O0la:after{
    background-image: linear-gradient(270deg, var(--back), var(--backTransparent));
    right: 0;
}

.Tournaments_tournament__1rsTm{
    padding: 20px;
    border-radius: 13px;
    border: 1px var(--border) solid;
}

.Tournaments_tournamentTitle__1WL11{
    font-size: 20px;
    font-weight: 600;
}

.Tournaments_sectionTitle__2LpmS{
    display: flex;
    align-items: center;
    color: var(--textSecondary);
    cursor: pointer;
}

.Tournaments_sectionTitleText__25UHN{
    width: calc(100% - 32px);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    white-space: nowrap;
}

.Tournaments_sectionTitle__2LpmS svg{
    display: block;
    margin-left: 8px;
    transition: transform .2s ease;
}

.Tournaments_sectionTitle__2LpmS svg.Tournaments_active__TK1ug{
    transform: rotate(180deg);
}

.Tournaments_collapseContent__3cYFR{
    padding-top: 8px;
}

.Tournaments_collapse__2ZXTi{
    transition: height .4s ease;
}

.Tournaments_tournamentDate__1_XXC{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
}

.Tournaments_tournamentDate__1_XXC .Tournaments_label__oOYbi, .Tournaments_tournamentDate__1_XXC .Tournaments_value__2KmmP{
    font-size: 14px;
    color: var(--textSecondary);
}

.Tournaments_tournamentDate__1_XXC .Tournaments_label__oOYbi{
    font-weight: 600;
}

.Tournaments_team__3CzX_{
    padding: 10px 12px;
    border-radius: 7px;
    border: 1px var(--border) solid;
    margin-bottom: 8px;
}

.Tournaments_teamTitle__m-8hk{
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
}

.Tournaments_teamLeader__jfs6A{
    margin-bottom: 4px;
    padding-bottom: 8px;
    border-bottom: 1px var(--border) solid;
}

.Tournaments_teamMember__3dhb8{
    padding: 4px 0;
}

.Tournaments_teamMember__3dhb8.Tournaments_withButton__7MFOT{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Tournaments_teamMember__3dhb8.Tournaments_withButton__7MFOT>div{
    width: calc(100% - 40px);
    margin-right: 8px;
}

.Tournaments_teamMember__3dhb8.Tournaments_withButton__7MFOT button{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    color: var(--textSecondary);
    padding: 4px;
    border: none;
    outline: none!important;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;
    transition: color .2s ease, background-color .2s ease;
}

.Tournaments_teamMember__3dhb8.Tournaments_withButton__7MFOT button svg{
    display: block;
}

.Tournaments_teamMember__3dhb8.Tournaments_withButton__7MFOT button:hover{
    color: var(--red);
}

.Tournaments_teamMember__3dhb8.Tournaments_withButton__7MFOT button:focus{
    background-color: var(--border);
}

.Tournaments_stepsHeader__JYAsR{
    border-bottom: 1px var(--border) solid;
    margin: -16px -24px 16px;
    position: relative;
}

.Tournaments_stepsHeader__JYAsR>div{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.Tournaments_stepsHeader__JYAsR::after, .Tournaments_stepsHeader__JYAsR::before{
    position: absolute;
    top: 0;
    content: "";
    display: block;
    pointer-events: none;
    width: 24px;
    height: 100%;
    z-index: 1;
}

.Tournaments_stepsHeader__JYAsR::after{
    right: 0;
    background: linear-gradient(270deg, var(--back), var(--backTransparent));
}

.Tournaments_stepsHeader__JYAsR::before{
    left: 0;
    background: linear-gradient(90deg, var(--back), var(--backTransparent));
}

.Tournaments_stepWrap__1rAgU{
    display: flex;
    padding: 8px 24px 12px 24px;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Tournaments_divider__372Az{
    height: 20px;
    width: 1px;
    margin: 0 12px;
    background-color: var(--border);
}

.Tournaments_stepsHeaderItem__1FnEf{
    height: 24px;
    width: 24px;
    font-size: 13px;
    font-weight: 600;
    color: var(--textSecondary);
    background-color: var(--border);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.15;
    cursor: pointer;
}

.Tournaments_stepsHeaderItem__1FnEf.Tournaments_selected__DK4O2{
    background-color: var(--primary);
    color: #ffffff;
}

.Tournaments_field__3oLKw{
    padding: 8px 0;
}

.Tournaments_field__3oLKw:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Tournaments_fieldLabel__2hLj7{
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 4px;
    color: var(--textSecondary)
}

.Tournaments_fieldValue__1OeMR{
    line-height: 1.2;
    font-size: 15px;
}


.Tournaments_Toolbar__SulHb{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Tournaments_Toolbar__SulHb button{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Tournaments_Toolbar__SulHb .Tournaments_arrowButton__2D5WB{
    padding: 0;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: var(--primary);
    color: #ffffff;
    width: 32px;
    height: 32px;
    outline: none;
    border-radius: 50%;
    transition: transform .2s ease;
    cursor: pointer;
}

.Tournaments_Toolbar__SulHb .Tournaments_arrowButton__2D5WB:disabled{
    background-color: var(--border);
    color: var(--textSecondary)
}

.Tournaments_Toolbar__SulHb .Tournaments_arrowButton__2D5WB:active{
    transform: scale(.95);
}

.Tournaments_arrowButton__2D5WB svg{
    display: block;
}

.Tournaments_results__2ZVgJ{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;
    margin-top: 8px;
}

.Tournaments_results__2ZVgJ thead{
    margin-bottom: 8px;
}

.Tournaments_results__2ZVgJ th{
    display: table-cell;
    padding: 4pt 8pt;
    font-size: 0.75rem;
    font-weight: normal;
    text-align: left;
    letter-spacing: 0px;
    min-height: 2.5rem;
    color: var(--textSecondary);
    border-bottom: 1px solid var(--border);
    border-top: 1px solid var(--border);
    border-radius: 0px;
}

.Tournaments_results__2ZVgJ th:first-child{
    border-bottom: 1px solid var(--border);
    border-left: 1px solid var(--border);
    border-top: 1px solid var(--border);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.Tournaments_results__2ZVgJ th:last-child{
    border-bottom: 1px solid var(--border);
    border-right: 1px solid var(--border);
    border-top: 1px solid var(--border);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.Tournaments_results__2ZVgJ td{
    font-size: 14px;
    padding: 4pt 8pt;
    border-top: 2px var(--back) solid;
    border-bottom: 2px var(--back) solid
}

.Tournaments_results__2ZVgJ td:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 2px var(--back) solid;
}

.Tournaments_results__2ZVgJ td:last-child{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 2px var(--back) solid;
}

.Tournaments_results__2ZVgJ tbody:before {
    content:"@";
    display:block;
    line-height: 1px;
    text-indent:-99999px;
}

.Tournaments_results__2ZVgJ tr.Tournaments_winner__3o56- td{
    background-color: var(--green);
    color: #ffffff;
}

.Tournaments_results__2ZVgJ tr:not(.Tournaments_winner__3o56-) td{
    background-color: var(--red);
    color: #ffffff;
}

@media (max-width: 948px){
    .Tournaments_tournaments__1jbXZ{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 900px){
    .Tournaments_tournaments__1jbXZ{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 768px){
    .Tournaments_tournaments__1jbXZ{
        grid-template-columns: 1fr;
    }
}
.fc-theme-standard .fc-list-day-cushion{
    background-color: var(--primary);
}

.fc-theme-standard .fc-list-day-cushion a{
    color: #fff!important;
}

.fc-list-event-title a{
    color: var(--text)!important;
}

.fc-theme-standard{
    --fc-border-color: var(--border)
}

.fc .fc-event:hover td{
    background-color: var(--border);
}

.fc-list{
    border-radius: 13px;
    overflow: hidden;
}

.fc-list-day-text{
    font-weight: 500;
    text-transform: capitalize;
}

.fc-list-day-side-text{
    line-height: 24px;
    font-size: 14px;
    font-weight: 500;
}

.fc-toolbar-chunk{
    display: flex;
}

.fc .fc-button{
    display: block;
    width: 100%;
    border: none;
    color: var(--primary);
    background-color: transparent;
    border: 2px var(--primary) solid!important;
    padding: 4px 12px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    transition: border-color 0.2s ease, background-color 0.2s ease,
        box-shadow 0.2s ease, transform 0.2s ease;
}

.fc .fc-button:focus {
    box-shadow: 0 4px 8px 0 var(--primaryTransparent)!important;
    outline: none !important;
}

.fc .fc-button {
    background-color: var(--primary)!important;
    color: #ffffff;
}
  
.fc .fc-button:hover {
    background-color: var(--primaryLighter)!important;
    border-color: var(--primaryLighter)!important;
}
  
.fc .fc-button:disabled {
    cursor: not-allowed;
    background-color: var(--border) !important;
    color: var(--textSecondary) !important;
    border-color: var(--border) !important;
}
.fc-toolbar-title{
    font-weight: 600;
    font-size: 24px!important;
}

@media (max-width: 490px){
    .fc-toolbar-title{
        display: none;
    }
    .fc-scroller{
        overflow: auto!important;
    }
    .fc-view-harness{
        height: -webkit-fit-content!important;
        height: -moz-fit-content!important;
        height: fit-content!important;
    }    
    .fc .fc-view-harness-active > .fc-view{
        height: -webkit-fit-content!important;
        height: -moz-fit-content!important;
        height: fit-content!important;
        position: unset!important;
    }
    .fc .fc-toolbar{
        flex-direction: row-reverse;
    }
    .fc-toolbar-chunk:last-of-type{
        width: 100%;
        justify-content: space-between;
    }
    .fc-toolbar-chunk:last-of-type>.fc-button{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}
.Dictionary_card__2wGDz {
    padding: 20px;
    border-radius: 13px;
    border: 1px var(--border) solid;
    margin: 24px 0;
}

.Dictionary_field__atEd4{
    margin-bottom: 12px;
}

.Dictionary_fab__mALal{
    position: fixed;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    height: 48px;
    width: 48px;
    bottom: 24px;
    right: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    border-radius: 50%;
    color: #ffffff;
    cursor: pointer;
    transition: transform .2s ease;
    margin-left: auto;
    margin-top: 16px;
    outline: none!important;
}

.Dictionary_fab__mALal svg{
    display: block;
}

.Dictionary_fab__mALal:active{
    transform: scale(0.95);
}

.Dictionary_wordOriginal__NP4Xr{
    font-weight: 700;
    font-size: 24px;
}

.Dictionary_wordTranslation__3XDiO{
    font-size: 14px;
    text-transform: uppercase;
    color: var(--textSecondary)
}

.Dictionary_wordMeaning__yfn5l{
    margin-top: 8px;
    font-size: 16px;
}

.Dictionary_wordExample__3YzLW{
    color: var(--textSecondary);
    font-style: italic;
    font-size: 15px;
    margin-top: 8px;
}

.Dictionary_date__OUOam{
    font-size: 12px;
    margin-top: 8px;
    text-transform: uppercase;
    color: var(--textSecondary);
}

.Dictionary_actions__2Z85p{
    display: flex;
    float: right;
}

.Dictionary_iconButton__vy2mG{
    display: block;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: none;
    background-color: transparent;
    color: var(--textSecondary);
    cursor: pointer;
    outline: none!important;
    padding: 8px;
    border-radius: 50%;
    transition: background-color .2s ease;
}

.Dictionary_iconButton__vy2mG:not(:last-of-type){
    margin-right: 4px;
}

.Dictionary_iconButton__vy2mG:hover{
    color: var(--text);
    background-color: var(--border);
}

.Dictionary_iconButton__vy2mG:focus{
    background-color: var(--border);
}

.Dictionary_iconButton__vy2mG svg{
    display: block;
}

.Dictionary_iconButton__vy2mG:hover .Dictionary_delete__8Gbh5{
    color: var(--red)
}
.Index_main__2IEOQ {
  padding-top: 68px;
}

.Index_main__2IEOQ ul{
  padding-left: 16px;
}

.Index_main__2IEOQ nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;
  box-shadow: 0 1px var(--border);
  z-index: 100;
  background: var(--backSemiTransparent);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.Index_main__2IEOQ nav .Index_container__EZWRF {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Index_container__EZWRF {
  max-width: 1000px;
  padding: 0 24px;
  margin: auto;
}

.Index_links__3eRaO a {
  font-size: 14px;
  font-weight: 500;
  margin: 8px;
  transition: color 0.2s ease;
  line-height: 1.2;
  color: var(--textSecondary) !important;
}

.Index_links__3eRaO a:hover {
  color: var(--text) !important;
}

.Index_navLeft__3wIMV {
  display: flex;
  align-items: center;
}

.Index_navLeft__3wIMV img {
  display: block;
  margin-right: 16px;
}

.Index_hero__uTduL {
  padding: 120px 0;
  --perspective: 350px;
  overflow: hidden;
  position: relative;
}

.Index_wallpaper__508Pd {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAABPklEQVR42k3KwUrjUBhA4XOTP00TpqXQcSPjwAy4n6UPMDAwK1/Fh3LlK7hwI4gKgjvRhVgpVZva1jS5ubm5v116NmfzmbCNLxkMqEXdChoLXiEYxHcKYDpQz/YmkNYT+vNrmBdQohoSpHIdtaIvnfLQRvrQBHbWc/7MbtifXJFv+hAPkdIFZo2a85Xq8ULN5XsMfpfD7oC/vuXA3+q+mWGeChse196cTVo9naqZlkIihrthRIimHJWn+k/vESHQd57vy4rfbw1FrdSS8suOWOR7nLT/uWiXSKzK0DbsLddMX0seK89lkrKTBtL+iMLs8ux/IlHbMaocP5aWpy0cF5aR9CgHQpYn5BLTQ5HEdQw2nfoNjD9UBy+Wb+6DzTBgxluYC04yJLWetFYyF5G1QlZF9BYNrArwGTLOMFnMJziKroaiR95JAAAAAElFTkSuQmCC");
}

.Index_hero__uTduL h1 {
  font-size: 64px;
  line-height: 1.12;
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  max-width: 600px;
}

.Index_hero__uTduL p {
  font-size: 20px;
  max-width: 540px;
  color: var(--textSecondary);
  margin: 24px 0;
}

.Index_buttons__1j3oW {
  display: flex;
}

.Index_buttons__1j3oW > :not(:last-of-type) {
  margin-right: 16px;
}

.Index_intro__2oOOO {
  position: relative;
  max-width: 100%;
  display: grid;
  grid-template-columns: 70% 1fr;
}

.Index_visual__12ASz {
  position: relative;
}

.Index_visual__12ASz .Index_windowCont__3Z7kY {
  perspective: var(--perspective);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.Index_visual__12ASz .Index_window__3x5bp {
  width: 1000px;
  position: absolute;
  left: 50%;
  top: 0;
  border-radius: 20px;
  box-shadow: 0 40px 80px rgba(45, 21, 71, 0.25);
}

.Index_visual__12ASz .Index_window__3x5bp img {
  width: 100%;
  border-radius: 20px;
  vertical-align: top;
}

.Index_visual__12ASz .Index_frame__3GkCY {
  width: 240px;
  transform: rotate3d(1, -1, 0, 3deg) translateZ(-60px);
  perspective: var(--perspective);
  transform-style: preserve-3d;
}

.Index_visual__12ASz .Index_frameImage__HZnOo {
  border-radius: 12px;
  overflow: hidden;
}

.Index_visual__12ASz .Index_frameImage__HZnOo img {
  width: 100%;
  vertical-align: top;
}

.Index_section__2CQiA {
  padding: 64px 0;
}

.Index_title__1Q0bn {
  font-family: "Manrope", sans-serif;
  font-size: 24px;
  color: var(--textSecondary);
}

.Index_logo__2oU6M {
  display: none !important;
}

.Index_items__3cwFX {
  display: grid;
  position: relative;
  padding-top: 48px;
  grid-gap: 32px;
  gap: 32px;
}

.Index_item__3BXEQ {
  width: 50%;
  margin: 32px 0;
}

.Index_item__3BXEQ:not(:last-of-type)::after {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
  margin-top: 48px;
  left: 0;
  background-color: var(--border);
}

.Index_item__3BXEQ:nth-child(even) {
  text-align: right;
  margin-left: auto;
}

.Index_item__3BXEQ:nth-child(even) .Index_itemSubtitle__1imkX,
.Index_item__3BXEQ:nth-child(even) .Index_itemLink__3CVTw, .Index_item__3BXEQ:nth-child(even) button{
  margin-left: auto;
}

.Index_item__3BXEQ:nth-child(1) .Index_itemTitle__3ysYc {
  background: linear-gradient(48deg, #eabf3c, #f95d44);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Index_item__3BXEQ:nth-child(2) .Index_itemTitle__3ysYc {
  background: linear-gradient(48deg, #ff5fc2, #47c8a5);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Index_item__3BXEQ:nth-child(3) .Index_itemTitle__3ysYc {
  background: linear-gradient(48deg, #eabf3c, #5ecf4e);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Index_item__3BXEQ:nth-child(4) .Index_itemTitle__3ysYc {
  background: linear-gradient(48deg, #47c8a5, #5ecf4e);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Index_itemTitle__3ysYc {
  font-size: 32px;
  font-family: "Manrope", sans-serif;
  background-size: 100% 100%;
  margin: 12px 0;
}

.Index_itemSubtitle__1imkX {
  font-size: 14px;
  line-height: 1.2;
  margin: 0;
  max-width: 290px;
  color: var(--textSecondary);
}

.Index_itemText__1NHOi {
  font-size: 18px;
  color: var(--textSecondary);
  font-weight: 500;
  line-height: 1.3;
}

.Index_more__IaV4W {
  display: flex;
  align-items: center;
  transition: opacity 0.2s ease;
}

.Index_more__IaV4W:hover {
  opacity: 0.7;
}

.Index_more__IaV4W span {
  display: block;
  padding-bottom: 2px;
  margin-right: 4px;
}

.Index_more__IaV4W svg {
  display: block;
  transition: transform 0.3s ease;
}

.Index_more__IaV4W:hover svg {
  transform: translateX(4px);
}

.Index_itemLink__3CVTw {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.Index_itemWarning__1sv0c {
  color: var(--red);
  font-weight: bold;
}

.Index_contacts__1B2c2 {
  padding: 96px 0;
  /*background-color: #fafafa;*/
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/static/media/photo.35fb4266.jpg);
  background-size: cover;
  background-position: center;
}

.Index_contacts__1B2c2 .Index_title__1Q0bn {
  color: #6c19cf;
}

.Index_contacts__1B2c2 .Index_subtitle__2INIk {
  color: var(--textSecondary);
}

.Index_card__2YFXN {
  max-width: 480px;
  background-color: #fff;
  padding: 32px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  gap: 16px;
  border-radius: 13px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08), 0 0 0 1px rgba(0, 0, 0, 0.04);
}

.Index_card__2YFXN p {
  margin: 0;
}

.Index_select__205iJ {
  position: relative;
}

.Index_select__205iJ select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  font-size: 16px;
  padding: 8px 34px 8px 14px;
  border: 1px var(--border) solid;
  background-color: var(--back);
  color: var(--text);
  border-radius: 5px;
}

.Index_select__205iJ svg {
  display: block;
  position: absolute;
  right: 14px;
  top: calc(50% - 10px);
}

.Index_select__205iJ select:focus {
  border-color: var(--primary);
  outline: none;
}

.Index_footer__2IpkT {
  padding: 32px 0;
  text-align: center;
  font-size: 14px;
  color: var(--textSecondary);
}

.Index_footer__2IpkT img {
  display: block;
  margin: auto;
  height: 64px;
  margin-bottom: 12px;
}

@media (max-width: 1024px) {
  .Index_intro__2oOOO {
    display: block;
  }
  .Index_hero__uTduL h1,
  .Index_hero__uTduL p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .Index_hero__uTduL h1 {
    font-size: 36px;
  }
  .Index_hero__uTduL p {
    font-size: 16px;
  }
  .Index_hero__uTduL .Index_buttons__1j3oW {
    justify-content: center;
  }
  .Index_visualCont__q90G5 {
    padding-top: 96px;
  }
  .Index_visual__12ASz {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .Index_item__3BXEQ {
    width: 100%;
  }
  .Index_item__3BXEQ:nth-child(even) {
    text-align: left;
  }
  .Index_item__3BXEQ:nth-child(even) .Index_itemSubtitle__1imkX,
  .Index_item__3BXEQ:nth-child(even) .Index_itemLink__3CVTw {
    margin: unset;
  }
}

@media (max-width: 768px) {
  .Index_main__2IEOQ nav .Index_links__3eRaO {
    display: none;
  }
  .Index_logo__2oU6M {
    display: block !important;
  }
  .Index_logoNoText__37yga {
    display: none !important;
  }
}

@media (max-width: 360px) {
  .Index_buttons__1j3oW {
    flex-direction: column;
    align-items: center;
  }
  .Index_buttons__1j3oW > :not(:last-of-type) {
    margin-right: 0;
    margin-bottom: 8px;
  }
  .Index_itemTitle__3ysYc {
    font-size: 24px;
  }
  .Index_itemSubtitle__1imkX {
    font-size: 14px;
    max-width: 100%;
  }
  .Index_itemText__1NHOi {
    font-size: 16px;
  }
}

.Login_Login__1wdco {
  margin: auto;
  display: flex;
}

.Login_plate__3Ds8L {
  width: 50%;
  width: 100%;
}

.Login_plate__3Ds8L h1 {
  font-size: 24px;
  margin: 0 0 24px 0;
}

.Login_plate__3Ds8L .Login_main__29fV- {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 40px;
  max-width: 400px;
  margin-left: auto;
  width: 100%;
}

.Login_brand__2T3df{
  display: flex;
  align-items: center;
}

.Login_brand__2T3df span{
  width: calc(100% - 44px);
  margin-left: 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 3px;
  color: var(--text);
}


.Login_picture__3IruB {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(135deg, #6c19cf 0%, #d93e62 100%);
}

.Login_picture__3IruB img {
  height: 50%;
}

.Login_field__1Qalh {
  margin-bottom: 16px;
}

.Login_Login__1wdco form {
  width: 100%;
}

.Login_link__2LXGT {
  display: block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 14px;
  margin-top: 12px;
}

.Login_Login__1wdco footer {
  font-size: 14px;
  margin-top: 24px;
  color: var(--textSecondary);
}

.Login_pattern__2CoS_{
  height: 100%;
  width: 100%;
  background-image: url(/static/media/pattern.3a11b384.png);
  background-size: 200px;
  opacity: .5;
}

@media (max-width: 768px){
  .Login_picture__3IruB{
    display: none;
  }
  .Login_plate__3Ds8L{
    width: 100%;
  }
  .Login_plate__3Ds8L .Login_main__29fV- {
    margin: auto;
  }
}
.Pay_Pay__1ndVN{
    padding: 48px 0;
}

.Pay_container__18uOg{
    max-width: 380px;
    margin: auto;
    padding: 0 24px;
}

.Pay_brand__ciM7d{
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.Pay_brand__ciM7d span{
    max-width: calc(100% - 44px);
    margin-left: 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3px;
    color: var(--text);
}

.Pay_brand__ciM7d + p{
    text-align: center;
    font-size: 14px;
    margin: 12px 0 0 0;
    color: var(--textSecondary)
}

.Pay_back__DmSu2{
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    font-size: 14px;
}

.Pay_back__DmSu2 svg{
    display: block;
    margin-right: 8px;
}

.Pay_Pay__1ndVN form{
    margin-top: 24px;
}

.Pay_result__s6j8T{
    padding: 24px 0;
    margin: 24px 0;
    border-top: 1px var(--border) solid;
}

.Pay_debt__2uADw{
    margin-top: 12px;
    font-size: 14px;
}

.Pay_sum__2IqWg p{
    font-size: 12px;
    line-height: 1.2;
    color: var(--textSecondary);
    margin: 12px 0;
    text-align: center;
}
.PrivacyPolicy_main__1wSly {
  padding-top: 68px;
}

.PrivacyPolicy_main__1wSly ul {
  padding-left: 16px;
}

.PrivacyPolicy_main__1wSly nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;
  box-shadow: 0 1px var(--border);
  z-index: 100;
  background: var(--backSemiTransparent);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.PrivacyPolicy_main__1wSly nav .PrivacyPolicy_container__2yxc3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PrivacyPolicy_container__2yxc3 {
  max-width: 1000px;
  padding: 0 24px;
  margin: auto;
}

.PrivacyPolicy_navLeft__2McE2 {
  display: flex;
  align-items: center;
}

.PrivacyPolicy_navLeft__2McE2 img {
  display: block;
  margin-right: 16px;
}

.PrivacyPolicy_logo__38VwQ {
  display: none !important;
}

.PrivacyPolicy_body__3Rbrt {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  text-align: left;
}

.PrivacyPolicy_body__3Rbrt h3 {
  margin: 0 auto;

}

.PrivacyPolicy_footer__1drjZ {
  padding: 32px 0;
  text-align: center;
  font-size: 14px;
  color: var(--textSecondary);
}

.PrivacyPolicy_footer__1drjZ img {
  display: block;
  margin: auto;
  height: 64px;
  margin-bottom: 12px;
}
.Navbar_Navbar__2foPt{
    padding: 16px 0;
    box-shadow: 0 -1px 0 0 var(--border) inset;
    position: fixed;
    width: 100%;
    -webkit-backdrop-filter: blur(4px) saturate(120%);
            backdrop-filter: blur(4px) saturate(120%);
    top: 0;
    z-index: 99;
    background-color: var(--backSemiTransparent);
}

.Navbar_Navbar__2foPt>div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Navbar_brand__ctf0P{
    display: flex;
    align-items: center;
}
  
.Navbar_brand__ctf0P span{
    max-width: calc(100% - 44px);
    margin-left: 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 3px;
    color: var(--text);
}

.Navbar_cart__IFv3a{
    position: relative;
}

.Navbar_cartBadge__Zc2QU{
    display: block;
    position: absolute;
    left: -12px;
    bottom: -8px;
    font-size: 10px;
    padding: 3px;
    line-height: 1.15;
    min-width: 20px;
    font-weight: 600;
    border-radius: 24px;
    text-align: center;
    color: #fff;
    background-color: var(--primary);
}

.Navbar_right__28v2Z{
    display: flex;
    align-items: center;
}

.Navbar_balance__2l08R{
    margin-left: 12px;
    border-left: 1px var(--border) solid;
    padding-left: 12px;
    line-height: 1.2;
}

.Navbar_balance__2l08R .Navbar_label__2rIqk{
    font-weight: bold;
    font-size: 10px;
    color: var(--textSecondary);
    text-transform: uppercase;
    margin-bottom: 4px;
}

.Navbar_balance__2l08R .Navbar_value__23nfL{
    font-size: 14px;
}

@media (max-width: 440px){
    .Navbar_brand__ctf0P span{
        display: none;
    }
}
.Cart_Cart__2kPKA{
    padding-top: 68px;
}

.Cart_Cart__2kPKA>div{
    padding-top: 48px;
    padding-bottom: 48px;
}

.Cart_item__N3LsP{
    display: flex;
    align-items: stretch;
    padding: 24px 0;
}

.Cart_item__N3LsP:not(:last-of-type){
    border-bottom: 1px var(--border) solid;
}

.Cart_itemPhoto__UJw3b{
    width: 96px;
    height: 96px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 7px;
    background-color: #ffffff;
    border: 1px var(--border) solid;
}

.Cart_itemInfo__2DAGA{
    width: calc(100% - 112px);
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Cart_itemMain__2KODb{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Cart_itemTotal__354-a{
    margin-left: 16px;
}

.Cart_itemName__xsUh_{
    font-size: 18px;
}

.Cart_itemName__xsUh_ span{
    margin-left: 8px;
    color: var(--textSecondary);
    font-size: 16px;
}

.Cart_total__hLx_8{
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px var(--border) solid;
    display: flex;
    align-items: center;
    font-size: 20px;
    justify-content: space-between;
}

.Cart_alert__1cIuS{
    background-color: var(--red);
    color: #ffffff;
    padding: 12px;
    text-align: center;
    font-weight: bold;
    border-radius: 7px;
}

.Cart_buy__25CqK{
    margin-top: 24px;
}

.Cart_buy__25CqK form{
    margin-top: 24px;
}

.Cart_field__1jU9Z, .Cart_doubleField__2_bFS{
    margin-bottom: 12px;
}

.Cart_doubleField__2_bFS{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 12px;
    gap: 12px;
}

.Cart_buttons__3rtNF{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
    gap: 12px;
}

.Cart_notice__1PrrE{
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px var(--border) solid;
}

.Cart_notice__1PrrE p{
    font-size: 14px;
    color: var(--textSecondary);
}

@media (max-width: 578px){
    .Cart_item__N3LsP{
        display: block;
    }
    .Cart_doubleField__2_bFS{
        grid-template-columns: 1fr;
    }
    .Cart_itemInfo__2DAGA{
        margin: 16px 0 0 0;
        width: 100%;
    }
}
.Item_image__1F6Fc{
    min-height: 240px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 0 0 1px var(--border) inset;
}

.Item_sliderWrap__1IX2E{
    position: relative;
    box-shadow: 0 0 0 1px var(--border);
    border-radius: 7px;
    overflow: hidden;
}

.Item_sliderWrap__1IX2E button{
    position: absolute;
    display: flex;
    align-items: center;
    padding: 0 16px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: transparent;
    height: 100%;
    top: 0;
    color: var(--primary);
    border: none;
    z-index: 10;
    cursor: pointer;
    outline: none;
}

.Item_sliderWrap__1IX2E button:disabled{
    color: var(--border);
}

.Item_sliderWrap__1IX2E button svg{
    display: block;
}

.Item_sliderWrap__1IX2E .Item_prev__14Q8p{
    left: 0;
}

.Item_sliderWrap__1IX2E .Item_next__9868q{
    right: 0;
}

.Item_dots__1RkyE{
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    z-index: 10;
    background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.08));
}

.Item_dot__1mQM3{
    margin: 16px 8px;
    cursor: pointer;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: var(--back);
    border: 1px var(--border) solid;
}

.Item_dot__1mQM3.Item_active__3vGEc{
    background-color: var(--primary);
    border: 1px var(--primary) solid;
}

.Item_slider__WBUT0{
    display: flex;
    transition: transform .2s ease;
}

.Item_slider__WBUT0 .Item_image__1F6Fc{
    box-shadow: none;
    flex-grow: 1;
    border-radius: 0px;
}

.Item_title__21InW{
    font-weight: 600;
}

.Item_description__2vTOq{
    margin: 4px 0 12px 0;
    font-size: 14px;
    color: var(--textSecondary);
}

.Item_price__2Sk5I{
    font-size: 20px;
    margin: 12px 0 4px 0;
    line-height: 1.15;
}

.Item_price__2Sk5I span{
    margin-left: 4px;
    color: var(--textSecondary);
    font-size: 14px;
}

.Item_buttons__3QuJz{
    display: flex;
    align-items: center;
}

.Item_buttons__3QuJz button:not(:last-of-type){
    margin-right: 8px;
}
.Index_hero__3DrnF{
    padding-top: 68px;
    background-image: url(/static/media/hero.11625d5a.png);
    background-position: center;
    background-size: cover;
    box-shadow: 0 -1px 0 0 var(--border) inset;
}

.Index_hero__3DrnF>div{
    padding: 128px 24px;
}

.Index_hero__3DrnF p{
    max-width: 640px;
    color: var(--textSecondary);
}

.Index_itemsWrap__cpOiX{
    padding: 48px 0;
}

.Index_itemsWrap__cpOiX p{
    color: var(--textSecondary);
    text-align: center;
}

.Index_items__X-rzu{
    display: grid;
    padding-top: 24px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
    gap: 48px;
}

.Index_back__XMVwe{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    margin-top: -16px;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.Index_back__XMVwe svg{
    display: block;
    margin-right: 8px;
}

@media (max-width: 1360px){
    .Index_hero__3DrnF{
        background-size: 150%;
    }
}

@media (max-width: 768px){
    .Index_items__X-rzu{
        grid-template-columns: 1fr;
        grid-gap: 48px;
        gap: 48px;
    }
    .Index_hero__3DrnF{
        text-align: center;
    }
    .Index_hero__3DrnF h1{
        font-size: 24px;
        line-height: 1.2;
    }
    .Index_hero__3DrnF p{
        font-size: 16px;
        padding: 0 24px;
    }
    .Index_back__XMVwe{
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 496px){
    .Index_items__X-rzu{
        grid-template-columns: 1fr;
        grid-gap: 48px;
        gap: 48px;
    }
    .Index_hero__3DrnF>div{
        padding: 64px 24px;
    }
}
