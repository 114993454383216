.main {
  padding-top: 68px;
}

.main ul {
  padding-left: 16px;
}

.main nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;
  box-shadow: 0 1px var(--border);
  z-index: 100;
  background: var(--backSemiTransparent);
  backdrop-filter: blur(4px);
}

.main nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  max-width: 1000px;
  padding: 0 24px;
  margin: auto;
}

.navLeft {
  display: flex;
  align-items: center;
}

.navLeft img {
  display: block;
  margin-right: 16px;
}

.logo {
  display: none !important;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  text-align: left;
}

.body h3 {
  margin: 0 auto;

}

.footer {
  padding: 32px 0;
  text-align: center;
  font-size: 14px;
  color: var(--textSecondary);
}

.footer img {
  display: block;
  margin: auto;
  height: 64px;
  margin-bottom: 12px;
}